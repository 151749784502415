import React, { useContext, useMemo } from 'react';
import { Card, Heading, Pane, Paragraph } from 'evergreen-ui';
import _ from 'lodash';

import { SelectedUploadRequest } from '../../../pages/upload-request/selected-upload-request-context';
import { Image } from '../../atoms';
import BuildingPlaceholderImage from '../../../assets/images/illustrations/placeholder-building.svg';
import AssetSidebarAssetDetails from '../assets-list/asset-sidebar-asset-details';

const UploadRequestAssetInfoSidebar = () => {
    const { selectedUploadRequest } = useContext(SelectedUploadRequest);

    const asset = useMemo(
        () =>
            _.get(selectedUploadRequest, 'asset') || {
                address: '',
                assetName: '',
                buildingUse: '',
                builtCoverage: '',
                builtFAR: '',
                erfNumber: '',
                grossBuildingArea: '',
                grossLettableArea: '',
                permissibleCoverage: '',
                permissibleFAR: '',
            },
        [selectedUploadRequest]
    );

    return (
        <Pane
            width={313}
            height="calc(100% - 65px)"
            position="fixed"
            right={0}
            top={65}
            backgroundColor="#FAFBFF"
            borderLeft="0.5px solid #C1C4D6"
            zIndex={20}
        >
            <Pane
                height={135}
                width={313}
                display="flex"
                flexDirection="column"
                position="fixed"
            >
                <Pane
                    display="flex"
                    justifyContent="space-between"
                    flexGrow={1}
                    padding={24}
                    width="100%"
                >
                    <Pane>
                        <Heading fontSize={22} fontWeight="500">
                            {asset?.assetName}
                        </Heading>
                        <Paragraph
                            size={400}
                            fontSize={12}
                            fontWeight={600}
                            color="#C1C4D6"
                        >
                            {asset?.address.addressLine1},{' '}
                            {asset?.address.suburb}
                        </Paragraph>
                    </Pane>
                    <Card
                        width={110}
                        height={110}
                        elevation={3}
                        borderRadius={10}
                    >
                        <Image
                            src={BuildingPlaceholderImage}
                            width={110}
                            height={110}
                            borderRadius={10}
                        />
                    </Card>
                </Pane>
            </Pane>
            <Pane
                height="calc(100% - 64px - 70px)"
                padding={28}
                overflowY="auto"
                overflowX="hidden"
                marginTop={135}
            >
                <AssetSidebarAssetDetails
                    asset={asset}
                    isVisible
                    canEditAsset={false}
                />
            </Pane>
        </Pane>
    );
};

export default UploadRequestAssetInfoSidebar;
