import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Pane, Paragraph, Text } from 'evergreen-ui';
import PropTypes from 'prop-types';

import { assetSelector } from '../../../reducers/asset-reducer/asset.reducer';

const AssetSidebarAssetDetails = ({ isVisible, asset }) => {
    const { currentAsset, currentBuilding, currentTenant } =
        useSelector(assetSelector);
    const {
        erfNumber,
        grossBuildingArea,
        grossLettableArea,
        builtFar,
        buildingUse,
        type,
        permissibleFar,
        permissibleCoverage,
        builtCoverage,
    } = useMemo(
        () =>
            asset ||
            currentTenant ||
            currentBuilding ||
            currentAsset || {
                portfolioName: '',
                erfNumber: '',
                grossBuildingArea: '',
                grossLettableArea: '',
                builtFar: '',
                permissibleFar: '',
                builtCoverage: '',
                permissibleCoverage: '',
                propertyType: '',
            },
        [currentAsset, currentBuilding, currentTenant, asset]
    );

    if (!isVisible) {
        return null;
    }

    return (
        <Pane borderBottom="0.5px solid #696F8C" paddingBottom={35}>
            <>
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    Erf No:&emsp;
                    <Text fontSize={12} fontWeight={600} color="#333333">
                        {erfNumber}
                    </Text>
                </Paragraph>
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    Building Use:&emsp;
                    <Text fontSize={12} fontWeight={600} color="#333333">
                        {buildingUse}
                    </Text>
                </Paragraph>
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    Property Type:&emsp;
                    <Text
                        fontSize={12}
                        fontWeight={600}
                        color="#333333"
                        textTransform="capitalize"
                    >
                        {type}
                    </Text>
                </Paragraph>
                <br />
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    GBA (Gross Building Area):&emsp;
                    <Text fontSize={12} fontWeight={600} color="#333333">
                        {grossBuildingArea}
                    </Text>
                </Paragraph>
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    GLA (Gross Lettable Area):&emsp;
                    {grossLettableArea && (
                        <Text fontSize={12} fontWeight={600} color="#333333">
                            {grossLettableArea}m&sup2;
                        </Text>
                    )}
                </Paragraph>
                <br />
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    Built FAR (Floor Area Ratio):&emsp;
                    <Text fontSize={12} fontWeight={600} color="#333333">
                        {builtFar}
                    </Text>
                </Paragraph>
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    Permissible FAR (Floor Area Ratio):&emsp;
                    <Text fontSize={12} fontWeight={600} color="#333333">
                        {permissibleFar}
                    </Text>
                </Paragraph>
                <br />
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    Built Coverage:&emsp;
                    <Text fontSize={12} fontWeight={600} color="#333333">
                        {builtCoverage}
                    </Text>
                </Paragraph>
                <Paragraph size={500} fontSize={12} color="#696F8C">
                    Permissible Coverage:&emsp;
                    <Text fontSize={12} fontWeight={600} color="#333333">
                        {permissibleCoverage}
                    </Text>
                </Paragraph>
            </>
        </Pane>
    );
};

AssetSidebarAssetDetails.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    asset: PropTypes.shape({}),
};

AssetSidebarAssetDetails.defaultProps = {
    asset: null,
};

export default AssetSidebarAssetDetails;
