import React, { useMemo } from 'react';
import { Pane } from 'evergreen-ui';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import ComplianceDocumentUploadHistory from './compliance-document-upload-history';
import { assetSelector } from '../../../reducers/asset-reducer/asset.reducer';
import AssetSidebarUploadRequest from './asset-sidebar-upload-requests';
import { colors } from '../../../theme/theme';
import AssetSidebarAssetDetails from './asset-sidebar-asset-details';

const ComplianceDetailsSidebar = () => {
    const { pathname } = useLocation();
    const { currentAsset, currentBuilding, currentTenant } =
        useSelector(assetSelector);

    const asset = useMemo(
        () => currentTenant || currentBuilding || currentAsset || null,
        [currentTenant, currentBuilding, currentAsset]
    );

    const isAssetInfoVisible = pathname.includes('info');

    if (!currentAsset && !currentBuilding && !currentTenant) {
        return null;
    }

    return (
        <PaneWithScrollbar
            width={313}
            right={20}
            top={276}
            bottom={20}
            backgroundColor="#FAFBFF"
            border="0.5px solid #C1C4D6"
            borderRadius={4}
            position="fixed"
            overflowY="auto"
        >
            <Pane padding={28}>
                <AssetSidebarAssetDetails
                    key={`${currentTenant?.id}-${currentBuilding?.id}-${currentAsset?.id}`}
                    isVisible={isAssetInfoVisible && !!asset}
                    asset={asset}
                />
                <AssetSidebarUploadRequest
                    isVisible={!isAssetInfoVisible && !!asset}
                />
                <ComplianceDocumentUploadHistory />
            </Pane>
        </PaneWithScrollbar>
    );
};

export default ComplianceDetailsSidebar;

const PaneWithScrollbar = styled(Pane)`
    border-radius: 4px;
    border: 0.5px solid #c1c4d6;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background-color: ${colors.transparent};
    }
    &::-webkit-scrollbar-thumb {
        background-color: #8f95b2;
        border-radius: 5px;
    }
`;
