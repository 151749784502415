import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Heading, Pane, Spinner, Text, Button } from 'evergreen-ui';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
    getAsset,
    getBuilding,
    getTenant,
} from '../../reducers/asset-reducer/asset.actions';
import AssetOverview from '../../components/organisms/asset-overview/asset-overview';
import { SelectedComplianceItemProvider } from './selected-complianceI-item-context';
import AssetDetailsSidebar from '../../components/organisms/assets-list/compliance-details-sidebar';
import { getAllContactsForBusiness } from '../../reducers/contacts-reducer/contacts.actions';
import EditAssetSidebar from '../../components/organisms/edit-asset-sidebar/edit-asset-sidebar';
import Breadcrumbs from '../../components/molecules/bread-crumbs';
import { assetSelector } from '../../reducers/asset-reducer/asset.reducer';
import { getNotifications } from '../../reducers/notifications-reducer/notifications.actions';
import { useUserAccess } from '../../context/user-access-context';

const AssetPage = () => {
    const { assetId, buildingId, tenantId } = useParams();

    const { currentRights } = useUserAccess();

    const { create, update } = useMemo(
        () =>
            currentRights || {
                create: false,
                update: false,
            },
        [currentRights]
    );

    const dispatch = useDispatch();

    const { currentAsset, currentBuilding, currentTenant } =
        useSelector(assetSelector);

    const [isEditAssetSidebarShown, setIsEditAssetSidebarShown] =
        useState(false);
    const [loading, setLoading] = useState(true);

    const getData = useCallback(async () => {
        if (tenantId && tenantId !== 'undefined' && !currentTenant) {
            await dispatch(getTenant(tenantId));
        }
        if (buildingId && buildingId !== 'undefined' && !currentBuilding) {
            await dispatch(getBuilding(buildingId));
        }
        if (assetId && assetId !== 'undefined' && !currentAsset) {
            await dispatch(getAsset(assetId, true, true, true));
        }
        await Promise.all([
            dispatch(getAllContactsForBusiness()),
            dispatch(getNotifications()),
        ]);
        setLoading(false);
    }, [
        tenantId,
        currentTenant,
        buildingId,
        currentBuilding,
        assetId,
        currentAsset,
        dispatch,
    ]);

    const asset = useMemo(
        () => currentTenant || currentBuilding || currentAsset,
        [currentTenant, currentBuilding, currentAsset]
    );

    useEffect(() => {
        getData();
    }, [getData]);

    const closeEditAssetSidebar = () => {
        setIsEditAssetSidebarShown(false);
    };

    const showEditAssetSidebar = () => {
        setIsEditAssetSidebarShown(true);
    };

    if (loading) {
        return (
            <Pane
                height="100%"
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
            >
                <Spinner />
                <Text marginTop="1rem">Loading...</Text>
            </Pane>
        );
    }

    return (
        <Pane display="flex" width="100%" margin={20} marginTop={0}>
            <Pane
                display="flex"
                flexDirection="column"
                height="100%"
                width="100%"
                gap={24}
            >
                <Pane
                    display="flex"
                    backgroundColor="#f2f4fb"
                    justifyContent="space-between"
                    width="100%"
                    position="sticky"
                    top={0}
                    zIndex={1}
                >
                    <Pane
                        display="flex"
                        flexDirection="column"
                        gap={24}
                        paddingY={24}
                        width="100%"
                    >
                        <Breadcrumbs />
                        <Pane
                            display="flex"
                            alignItems="center"
                            width="100%"
                            justifyContent="space-between"
                        >
                            <LinkHeading
                                size={900}
                                fontSize={28}
                                display="flex"
                            >
                                {asset?.assetName}
                            </LinkHeading>

                            {(create || update) && (
                                <Button
                                    onClick={showEditAssetSidebar}
                                    appearance="primary"
                                >
                                    Edit Information
                                </Button>
                            )}
                        </Pane>
                    </Pane>
                </Pane>
                <Pane
                    display="flex"
                    flex={1}
                    width="100%"
                    height="100%"
                    justifyContent="space-between"
                    position="relative"
                    paddingRight={333}
                >
                    <Pane display="flex" width="100%">
                        <AssetOverview />
                    </Pane>
                    <AssetDetailsSidebar
                        showEditSidebar={showEditAssetSidebar}
                    />
                </Pane>
            </Pane>
            {(create || update) && (
                <EditAssetSidebar
                    isShown={isEditAssetSidebarShown}
                    asset={{ ...asset }}
                    close={closeEditAssetSidebar}
                />
            )}
        </Pane>
    );
};

export default (props) => (
    <SelectedComplianceItemProvider>
        <AssetPage {...props} />
    </SelectedComplianceItemProvider>
);

const LinkHeading = styled(Heading)`
    display: flex;
    cursor: default;
`;
