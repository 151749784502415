import React from 'react';
import { Image as EvergreenImage } from 'evergreen-ui';
import styled from 'styled-components';

const Image = React.forwardRef((props, ref) => (
    <StyledImage width="100%" height="auto" ref={ref} {...props} />
));

export default Image;

const StyledImage = styled(EvergreenImage)`
    object-fit: cover;
`;
