import React, { useEffect, useState } from 'react';
import {
    Pane,
    Dialog,
    Button,
    PlusIcon,
    Text,
    TextInputField,
    CrossIcon,
} from 'evergreen-ui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { taskSelector } from '../../reducers/task-reducer/task.reducer';
import { getTaskCategoryModel } from '../../models/task.models';
import TaskCategoryItem from './task-category-item';
import { colors } from '../../theme/theme';
import {
    createTasksType,
    createTasksCategory,
    deleteTasksCategory,
    updateTasksType,
    updateTasksCategory,
    getTasksCategories,
} from '../../reducers/task-reducer/task.actions';
import { successToast, errorToast } from '../toasts';

function TaskCategoriesModal({ close, isShown }) {
    const dispatch = useDispatch();

    const { categories } = useSelector(taskSelector);

    const [addingCategory, setAddingCategory] = useState(false);
    const [categoryLoading, setCategoryLoading] = useState(false);
    const [deletingCategory, setDeletingCategory] = useState(null);
    const [editingCategory, setEditingCategory] = useState(false);
    const [loading, setLoading] = useState(false);
    const [categoryForm, setCategoryForm] = useState({
        values: {
            name: '',
            original: '',
        },
        errors: { name: null, abbreviation: null },
    });

    const [addingTypes, setAddingTypes] = useState({});

    const handleAddTypes = (categoryId, value, typeId = null) => {
        if (typeId) {
            setAddingTypes((state) => ({
                ...state,
                [categoryId]: {
                    ...state[categoryId],
                    value,
                    typeId,
                },
            }));
        } else {
            setAddingTypes((state) => ({
                ...state,
                [categoryId]: {
                    ...state[categoryId],
                    value,
                },
            }));
        }
    };

    const handleCancelAddTypes = (categoryId) => {
        setAddingTypes((state) => {
            const newState = { ...state };
            delete newState[categoryId];
            return newState;
        });
    };

    const handleCategoryChange = (e) => {
        setCategoryForm((state) => ({
            ...state,
            values: { ...state.values, [e.target.name]: e.target.value },
            errors: { ...state.errors, [e.target.name]: null },
        }));
    };

    const handleCategorySubmit = async ({ name, categoryId }) => {
        if (!name) {
            setCategoryForm((state) => ({
                ...state,
                errors: { ...state.errors, name: 'Please provide a Name' },
            }));
            return;
        }

        try {
            setCategoryLoading(true);
            setLoading(true);
            if (addingCategory) {
                await dispatch(createTasksCategory(name));
            } else if (editingCategory) {
                await dispatch(updateTasksCategory({ categoryId, name }));
            }
            setEditingCategory(false);
            setAddingCategory(false);
            setCategoryForm({
                values: {
                    name: '',
                    abbreviation: '',
                },
                errors: {
                    name: null,
                    abbreviation: null,
                },
            });
            successToast(
                `Successfully ${
                    editingCategory ? 'updated' : 'created'
                } ${name}`
            );
        } catch (error) {
            setCategoryForm((form) => ({
                ...form,
                errors: {
                    ...form.errors,
                    name: get(error, 'response.data.message', error.message),
                },
            }));
        } finally {
            setLoading(false);
            setCategoryLoading(false);
        }
    };

    const handleCancel = () => {
        if (addingCategory || editingCategory) {
            setAddingCategory(false);
            setEditingCategory(false);
            setCategoryForm({
                values: {
                    name: '',
                    original: '',
                },
                errors: { name: null, abbreviation: null },
            });
        }
        if (!addingCategory && !editingCategory) {
            close();
        }
    };

    const submitType = async () => {
        const newTypes = Object.keys(addingTypes);
        if (newTypes.length) {
            newTypes.map((categoryId) => {
                const { value, typeId } = addingTypes[categoryId];
                if (typeId) {
                    return dispatch(updateTasksType({ typeId, value }));
                }
                return dispatch(
                    createTasksType({ categoryId, newTypeName: value })
                );
            });
        }
    };

    const handleConfirm = async () => {
        if (addingCategory || editingCategory) {
            handleCategorySubmit(categoryForm.values);
        } else if (Object.keys(addingTypes).length) {
            setLoading(true);
            await submitType();
            close();
            setLoading(false);
        }
        if (
            !addingCategory &&
            !editingCategory &&
            !Object.keys(addingTypes).length
        ) {
            close();
        }
    };

    const handleCategoryDelete = async (e, categoryId) => {
        e.stopPropagation();
        try {
            setDeletingCategory(categoryId);
            await dispatch(deleteTasksCategory(categoryId));
            successToast('Category Successfully Deleted');
        } catch (error) {
            errorToast(get(error, 'response.data.message', error.message));
        } finally {
            setDeletingCategory(null);
        }
    };

    const handleCategoryEnter = ({ key }) => {
        if (key === 'Enter') {
            if (addingCategory && !categoryForm.values.name) {
                setCategoryForm((state) => ({
                    ...state,
                    errors: {
                        ...state.errors,
                        name: 'Please provide a Category  Name',
                    },
                }));
            } else {
                handleConfirm();
            }
        }
    };

    const setEditCategoryForm = (e, name, abbreviation, categoryId) => {
        e.stopPropagation();
        setCategoryForm({
            values: {
                categoryId,
                name,
                original: name,
            },
            errors: { name: null },
        });
        setAddingCategory(false);
        setEditingCategory(true);
    };

    useEffect(() => {
        dispatch(getTasksCategories());
    }, [dispatch]);

    return (
        <Dialog
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            title="Edit Asset Categories"
            isShown={isShown}
            isConfirmLoading={loading}
            onCloseComplete={close}
        >
            <Pane>
                <>
                    {addingCategory || editingCategory ? (
                        <Pane
                            display="flex"
                            alignItems="center"
                            gap={15}
                            paddingRight={10}
                        >
                            <Pane width="100%">
                                <TextInputField
                                    name="name"
                                    label={
                                        addingCategory
                                            ? 'Add New Category'
                                            : `Editing ${categoryForm.values.original}`
                                    }
                                    placeholder="Category Name"
                                    value={categoryForm.values.name}
                                    inputHeight={40}
                                    onChange={handleCategoryChange}
                                    onKeyDown={handleCategoryEnter}
                                    validationMessage={categoryForm.errors.name}
                                />
                            </Pane>
                            <Pane
                                role="button"
                                cursor="pointer"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                onClick={handleCancel}
                                hoverElevation={1}
                                padding={5}
                                borderRadius={9999}
                            >
                                <CrossIcon color="#8f95b2" />
                            </Pane>
                        </Pane>
                    ) : (
                        <Button
                            iconBefore={<PlusIcon />}
                            marginLeft={-8}
                            onClick={() => setAddingCategory(true)}
                            appearance="minimal"
                            isLoading={categoryLoading}
                        >
                            Add New Category
                        </Button>
                    )}

                    <Pane overflowY="auto">
                        {categories.length ? (
                            categories.map((category) => {
                                const {
                                    abbreviation,
                                    name,
                                    types,
                                    id,
                                    isDefault,
                                } = getTaskCategoryModel(category);

                                return (
                                    <TaskCategoryItem
                                        key={id}
                                        name={name}
                                        isDefault={isDefault}
                                        abbreviation={abbreviation}
                                        categoryId={id}
                                        types={types}
                                        handleCategoryDelete={
                                            handleCategoryDelete
                                        }
                                        setEditCategoryForm={
                                            setEditCategoryForm
                                        }
                                        handleCancelAddTypes={
                                            handleCancelAddTypes
                                        }
                                        handleAddTypes={handleAddTypes}
                                        deletingCategory={deletingCategory}
                                    />
                                );
                            })
                        ) : (
                            <Pane
                                borderBottom={`1px solid ${colors.neutral500}`}
                                display="flex"
                                width="100%"
                                height={40}
                                alignItems="center"
                            >
                                <Text fontWeight={500} fontSize={15}>
                                    There are no Categories
                                </Text>
                            </Pane>
                        )}
                    </Pane>
                </>
            </Pane>
        </Dialog>
    );
}

TaskCategoriesModal.propTypes = {
    close: PropTypes.func.isRequired,
    isShown: PropTypes.bool.isRequired,
};

export default TaskCategoriesModal;
