import React, { useMemo, useState } from 'react';
import { CircleArrowUpIcon, Heading, Pane } from 'evergreen-ui';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { Image } from '../../atoms';
import { fileUploadService, imagesService } from '../../../services';
import BuildingPlaceholderImage from '../../../assets/images/illustrations/placeholder-building.svg';
import { colors } from '../../../theme/theme';
import ImageUploadDialog from '../../molecules/image-upload-dialog';
import { uploadImageUrlsModel } from '../../../models';
import { assetSelector } from '../../../reducers/asset-reducer/asset.reducer';
import { successToast } from '../../toasts';
import {
    getAsset,
    getBuilding,
    getTenant,
} from '../../../reducers/asset-reducer/asset.actions';
import { BuildingImage } from '../../molecules';
import { useUserAccess } from '../../../context/user-access-context';

const BuildingImageGallery = ({ images, isTenant }) => {
    const dispatch = useDispatch();

    const { currentRights } = useUserAccess();

    const { create, update } = useMemo(
        () =>
            currentRights || {
                create: false,
                update: false,
            },
        [currentRights]
    );

    const { currentAsset, currentBuilding, currentTenant } =
        useSelector(assetSelector);

    const { assetId, buildingId, tenantId } = useParams();

    const [isUploadDialogShown, setIsUploadDialogShown] = useState(false);

    const featuredImage = useMemo(() => {
        if (_.size(images) <= 0) {
            return null;
        }

        return _.find(images, (image) => image.isFeatured);
    }, [images]);

    const showUploadDialog = () => {
        if (!create || !update) {
            return;
        }
        setIsUploadDialogShown(true);
    };

    const selectedAssetId = useMemo(
        () => currentTenant?.id || currentBuilding?.id || currentAsset?.id,
        [currentTenant, currentBuilding, currentAsset]
    );

    const uploadImage = async (file, controller, setUploadProgress) => {
        let fileType = '';
        if (file.type) {
            fileType = file.type.substring(
                file.type.indexOf('/') + 1,
                file.type.length
            );
        } else {
            fileType = file.name.substring(
                file.type.indexOf('.') + 1,
                file.type.length
            );
        }

        const { fileUrl, fileId, thumbnailId } = uploadImageUrlsModel(
            await imagesService.getImageUploadUrl(
                {
                    assetId: selectedAssetId,
                    filetype: {
                        thumbnail: 'image/jpeg',
                        file: fileType,
                    },
                },
                controller
            )
        );

        await fileUploadService.uploadFile(
            file,
            fileUrl,
            controller,
            setUploadProgress
        );

        return { fileId, thumbnailId };
    };

    const uploadSuccess = async (file, fileId) => {
        await imagesService.setImageUploadedSuccessfully({
            assetId: selectedAssetId,
            isSpace: false,
            spaceId: null,
            fileId,
            thumbnailId: '',
            isFeatured: true,
        });
        if (tenantId) {
            dispatch(getTenant(tenantId));
        }
        if (buildingId) {
            dispatch(getBuilding(buildingId));
        }
        if (assetId) {
            dispatch(getAsset(assetId, false, true));
        }

        successToast(`Successfully uploaded building image.`);
    };

    const renderPlaceholderImage = () => {
        if (_.size(images) > 0) {
            return null;
        }

        return (
            <UploadPane
                display="flex"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                cursor={create || update ? 'pointer' : 'default'}
                onClick={showUploadDialog}
                position="relative"
                width="100%"
                height={350}
                rights={currentRights}
            >
                <Image
                    src={BuildingPlaceholderImage}
                    height="100%"
                    width="100%"
                />
                <Pane
                    id="download-overlay"
                    backgroundColor={colors.black50}
                    position="absolute"
                    height="100%"
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <CircleArrowUpIcon color={colors.white} size={80} />
                    <Heading color={colors.white} marginTop={10}>
                        Upload Image
                    </Heading>
                </Pane>
            </UploadPane>
        );
    };

    const renderGallery = () => {
        if (_.size(images) <= 0) {
            return null;
        }

        return (
            <UploadPane
                display="flex"
                justifyContent="center"
                alignItems="center"
                flex={1}
                overflow="hidden"
                cursor="pointer"
                onClick={showUploadDialog}
                position="relative"
                height="40%"
                width="100%"
            >
                <BuildingImage
                    image={featuredImage}
                    isTenant={isTenant}
                    width="100%"
                    height="350px"
                />
                <Pane
                    id="download-overlay"
                    backgroundColor={colors.black50}
                    position="absolute"
                    height="100%"
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <CircleArrowUpIcon color={colors.white} size={80} />
                    <Heading color={colors.white} marginTop={10}>
                        Upload Image
                    </Heading>
                </Pane>
            </UploadPane>
        );
    };

    return (
        <Pane>
            {renderPlaceholderImage()}
            {renderGallery()}
            <ImageUploadDialog
                isShown={isUploadDialogShown}
                setIsShown={setIsUploadDialogShown}
                uploadDocument={uploadImage}
                uploadSuccess={uploadSuccess}
                allowOnlyImages
            />
        </Pane>
    );
};

BuildingImageGallery.propTypes = {
    images: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isTenant: PropTypes.bool,
};

BuildingImageGallery.defaultProps = {
    isTenant: false,
};

export default BuildingImageGallery;

const UploadPane = styled(Pane)`
    #download-overlay {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }

    &:hover {
        #download-overlay {
            visibility: ${({ rights }) =>
                rights?.create || rights?.update ? 'visible' : 'none'};
            opacity: 100;
        }
    }
`;
