import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Avatar,
    Pane,
    CogIcon,
    NotificationsIcon,
    Popover,
    Position,
    Menu,
    LogOutIcon,
    PeopleIcon,
    LabelIcon,
    Text,
} from 'evergreen-ui';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';

import { Logo } from '../atoms';
import { colors } from '../../theme/theme';
import { userSelector } from '../../reducers/user-reducer/user.reducer';
import { logout } from '../../reducers/auth-reducer/auth.actions';
import UploadManagerMenu from '../organisms/upload-manager/upload-manager-menu';
import DashboardNavbarSearch from './search-navbar/dashboard-navbar-search';
import { notificationsSelector } from '../../reducers/notifications-reducer/notifications.reducer';
import { getNotifications } from '../../reducers/notifications-reducer/notifications.actions';
import NotificationsSidebar from '../notifications/notifications-sidebar';
import { useUserAccess } from '../../context/user-access-context';

const DashboardNavbar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector(userSelector);

    const { pathname } = useLocation();

    const { modules } = useUserAccess();

    const [showNotifications, setShowNotifications] = useState(false);
    const [showOptions, setShowOptions] = useState(false);

    const signOut = () => {
        dispatch(logout());
        navigate('/login', { replace: true });
    };

    const { notifications } = useSelector(notificationsSelector);

    const openNotificationsSidebar = () => {
        setShowNotifications(true);
    };

    const closeNotificationsSidebar = () => {
        setShowNotifications(false);
    };

    const hasManagementAccess = useMemo(
        () => modules['user-management'] || modules['template-management'],
        [modules]
    );

    useEffect(() => {
        dispatch(getNotifications());
    }, [dispatch]);

    return (
        <>
            <Pane
                paddingX={20}
                height={65}
                backgroundColor={colors.primary500}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                position="fixed"
                zIndex={5}
            >
                <Logo color="white" />
                <Pane display="flex" alignItems="center" height="100%">
                    {user && <UploadManagerMenu />}
                    {hasManagementAccess && (
                        <>
                            <Popover
                                isShown={showOptions}
                                position={Position.BOTTOM_RIGHT}
                                shouldCloseOnExternalClick
                                content={
                                    <Menu>
                                        <Menu.Group>
                                            {modules['template-management'] && (
                                                <Menu.Item
                                                    icon={LabelIcon}
                                                    onSelect={() => {
                                                        setShowOptions(false);
                                                        navigate(
                                                            '/template-management'
                                                        );
                                                    }}
                                                >
                                                    Template Management
                                                </Menu.Item>
                                            )}
                                            {modules['user-management'] && (
                                                <Menu.Item
                                                    icon={PeopleIcon}
                                                    onSelect={() => {
                                                        setShowOptions(false);
                                                        navigate(
                                                            '/user-management'
                                                        );
                                                    }}
                                                >
                                                    User Management
                                                </Menu.Item>
                                            )}
                                        </Menu.Group>
                                    </Menu>
                                }
                            >
                                <BackgroundHover
                                    id="template-icon"
                                    isActive={pathname.includes('management')}
                                >
                                    <Pane
                                        onClick={() => setShowOptions(true)}
                                        display="flex"
                                        justifyContent="center"
                                        width={40}
                                        position="relative"
                                    >
                                        <CogIcon color="#f2f4fa" size={20} />
                                    </Pane>
                                </BackgroundHover>
                            </Popover>
                            {showOptions && (
                                <Pane
                                    height="100%"
                                    width="100%"
                                    position="fixed"
                                    top={0}
                                    left={0}
                                    onClick={() => setShowOptions(false)}
                                />
                            )}
                        </>
                    )}
                    {!pathname.includes('upload-request') && (
                        <BackgroundHover
                            id="notifications"
                            onClick={openNotificationsSidebar}
                        >
                            <Pane
                                display="flex"
                                justifyContent="center"
                                width={40}
                                position="relative"
                                role="button"
                            >
                                <NotificationsIcon color="#f2f4fa" size={20} />
                                {!!notifications.length && (
                                    <Pane
                                        borderRadius={99999}
                                        background="#df2c2c"
                                        position="absolute"
                                        top={-4}
                                        right={3}
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        width={14}
                                        height={14}
                                        zIndex={10}
                                    >
                                        <Text fontSize={10} color="white">
                                            {notifications.length}
                                        </Text>
                                    </Pane>
                                )}
                            </Pane>
                        </BackgroundHover>
                    )}

                    {user && (
                        <Popover
                            position={Position.BOTTOM_RIGHT}
                            content={
                                <Menu>
                                    <Menu.Group>
                                        <Menu.Item
                                            icon={LogOutIcon}
                                            onSelect={signOut}
                                        >
                                            Logout
                                        </Menu.Item>
                                    </Menu.Group>
                                </Menu>
                            }
                        >
                            <BackgroundHover>
                                <Avatar
                                    name={`${user.firstName} ${user.lastName}`}
                                    size={40}
                                />
                            </BackgroundHover>
                        </Popover>
                    )}
                </Pane>
            </Pane>
            {!pathname.includes('upload-request') && (
                <>
                    <DashboardNavbarSearch />
                    <NotificationsSidebar
                        isShown={showNotifications}
                        close={closeNotificationsSidebar}
                        notifications={notifications}
                    />
                </>
            )}
        </>
    );
};

export default DashboardNavbar;

const BackgroundHover = styled.div`
    padding-left: 8px;
    padding-right: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: ${({ isActive }) =>
        isActive ? '#24374b' : 'transparent'};

    &:hover {
        background-color: #24374b;
    }
`;
