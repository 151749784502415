import React, { useMemo, useState } from 'react';
import {
    Heading,
    Pane,
    Position,
    Spinner,
    Tab,
    Tablist,
    Text,
    Button,
    Tooltip,
    PlusIcon,
} from 'evergreen-ui';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';

import { useLocation } from 'react-router-dom';
import CreatePortfolioSidebarForm from '../../components/organisms/create-portfolio-sidebar-form/create-portfolio-sidebar-form';
import { colors } from '../../theme/theme';
import AssetsList from '../../components/organisms/assets-list/assets-list';
import { PORTFOLIO_TYPES } from '../../components/molecules/portfolio-type-select';
import { userSelector } from '../../reducers/user-reducer/user.reducer';
import Breadcrumbs from '../../components/molecules/bread-crumbs';
import AssetDetailsSideSheet from '../../components/portfolio/asset-details-sidesheet';
import { assetSelector } from '../../reducers/asset-reducer/asset.reducer';
import { useUserAccess } from '../../context/user-access-context';

const addPortfolioButtonEnabled = false;

const PortfolioBuildingsPage = () => {
    const { pathname } = useLocation();
    const { allRights } = useUserAccess();

    const rights = useMemo(
        () =>
            allRights?.info || {
                create: false,
                read: false,
                update: false,
                delete: false,
            },
        [allRights]
    );

    const [showAddAssetSideSheet, setShowAddAssetSideSheet] = useState(false);
    const [isRolledUp, setIsRolledUp] = useState(false);
    const [isFormSideSheetShown, setIsFormSideSheetShown] = useState(false);
    const [_showAssetDetailsSideSheet, setShowAssetDetailsSideSheet] = useState(
        {
            isShown: false,
            asset: null,
        }
    );
    const [selectedIndex, setSelectedIndex] = useState(0);

    const { portfolios, isLoading } = useSelector(assetSelector);

    const { businessFriendlyName } = useSelector(userSelector);

    const selectedPortfolio = useMemo(() => {
        if (selectedIndex === 0) {
            return 'ALL';
        }

        return portfolios[selectedIndex - 1];
    }, [portfolios, selectedIndex]);

    const openFormSideSheet = () => setIsFormSideSheetShown(true);

    const closeFormSideSheet = () => setIsFormSideSheetShown(false);

    const showAssetDetailsSideSheet = async (e, asset) => {
        e.stopPropagation();
        setShowAssetDetailsSideSheet({
            isShown: true,
            asset,
        });
    };

    const closeAssetDetailsSideSheet = () => {
        setShowAssetDetailsSideSheet({
            isShown: false,
            asset: null,
        });
    };

    const toggleRollupFilter = () => {
        setIsRolledUp((prev) => !prev);
    };

    const renderLoading = () => {
        if (isLoading) {
            return (
                <Pane
                    height="100%"
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Spinner />
                    <Text marginTop="1rem">Loading...</Text>
                </Pane>
            );
        }
        return null;
    };

    const renderContent = () => {
        if (isLoading) {
            return null;
        }

        return (
            <Pane
                display="flex"
                flexDirection="column"
                gap={40}
                overflowX="hidden"
                overflowY="auto"
            >
                <Pane
                    paddingTop={16}
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    position="sticky"
                    top={0}
                    height={106}
                    zIndex={1}
                    gap={12}
                    backgroundColor="#f2f4fa"
                >
                    <Pane display="flex" flexDirection="column" gap={12}>
                        <Breadcrumbs path={pathname} />
                        <Pane
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Heading size={700} fontSize={24}>
                                {businessFriendlyName} Portfolio
                            </Heading>
                            <Pane display="flex" gap={6}>
                                <Button
                                    appearance="default"
                                    onClick={toggleRollupFilter}
                                    background="#f2f4fa"
                                >
                                    {isRolledUp ? 'Show All' : 'Roll-up'}
                                </Button>
                                {rights?.create && (
                                    <Button
                                        appearance="primary"
                                        iconBefore={<PlusIcon />}
                                        onClick={() =>
                                            setShowAddAssetSideSheet(true)
                                        }
                                    >
                                        Add New Property
                                    </Button>
                                )}
                            </Pane>
                        </Pane>
                    </Pane>
                    <Pane
                        display="flex"
                        backgroundColor="white"
                        borderRadius={4}
                        elevation={1}
                    >
                        <Tablist
                            display="flex"
                            alignItems="end"
                            paddingLeft={23}
                            height={40}
                        >
                            <StyledTab
                                key={0}
                                id={0}
                                onSelect={() => setSelectedIndex(0)}
                                isSelected={selectedIndex === 0}
                                backgroundColor={colors.white}
                                borderRadius={0}
                                borderBottom={
                                    selectedIndex === 0
                                        ? '3px solid #A3E6CD'
                                        : 'none'
                                }
                                fontWeight={selectedIndex === 0 ? '600' : 500}
                                fontSize={16}
                                color="#0C2138"
                            >
                                All Assets
                            </StyledTab>
                            {_.map(
                                portfolios.filter((portfolio) => !!portfolio),
                                (portfolio, index) => (
                                    <StyledTab
                                        key={index + 1}
                                        id={index + 1}
                                        borderRadius={0}
                                        onSelect={() =>
                                            setSelectedIndex(index + 1)
                                        }
                                        isSelected={index + 1 === selectedIndex}
                                        aria-controls={`panel-${portfolio}`}
                                        backgroundColor={colors.white}
                                        borderBottom={
                                            index + 1 === selectedIndex
                                                ? '3px solid #A3E6CD'
                                                : 'none'
                                        }
                                        fontWeight={
                                            index + 1 === selectedIndex
                                                ? '700'
                                                : '500'
                                        }
                                        fontSize={16}
                                        color="#0C2138"
                                    >
                                        {portfolio}
                                    </StyledTab>
                                )
                            )}
                            {addPortfolioButtonEnabled &&
                                PORTFOLIO_TYPES.length > _.size(portfolios) && (
                                    <Tooltip
                                        content="Info text goes here"
                                        position={Position.TOP_RIGHT}
                                    >
                                        <StyledTab
                                            key="add-new"
                                            id="add-new"
                                            onSelect={openFormSideSheet}
                                            fontSize={16}
                                            color="#8F95B2"
                                        >
                                            + Add Building Use
                                        </StyledTab>
                                    </Tooltip>
                                )}
                        </Tablist>
                    </Pane>
                </Pane>

                <AssetsList
                    selectedPortfolio={selectedPortfolio}
                    showAddAssetSideSheet={showAddAssetSideSheet}
                    closeAddAssetSideSheet={() =>
                        setShowAddAssetSideSheet(false)
                    }
                    showAssetDetailsSideSheet={showAssetDetailsSideSheet}
                    isRolledUp={isRolledUp}
                />
            </Pane>
        );
    };

    return (
        <Pane
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            height="100%"
            width="100%"
            paddingX={24}
        >
            {renderLoading()}
            {renderContent()}
            <CreatePortfolioSidebarForm
                close={closeFormSideSheet}
                isShown={isFormSideSheetShown}
            />
            <AssetDetailsSideSheet
                isShown={_showAssetDetailsSideSheet.isShown}
                close={closeAssetDetailsSideSheet}
                asset={_showAssetDetailsSideSheet.asset}
            />
        </Pane>
    );
};

export default PortfolioBuildingsPage;

const StyledTab = styled(Tab)`
    &[aria-selected='true'] {
        background-color: #ffffff;
    }

    &:hover {
        background-color: #ffffff;
    }

    &:focus {
        box-shadow: none;
    }
`;
