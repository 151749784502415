import React, {
    useEffect,
    useState,
    useCallback,
    useMemo,
    useContext,
} from 'react';
import { Pane, Heading } from 'evergreen-ui';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { assetSelector } from '../../reducers/asset-reducer/asset.reducer';
import {
    SelectedComplianceItemProvider,
    SelectedComplianceItem,
} from './selected-complianceI-item-context';
import Breadcrumbs from '../../components/molecules/bread-crumbs';
import DocumentsList from './documents-list';
import ComplianceSidebar from '../../components/organisms/compliance-sidebar/compliance-sidebar';
import {
    getAsset,
    getBuilding,
    getTenant,
} from '../../reducers/asset-reducer/asset.actions';
import { getAllContactsForBusiness } from '../../reducers/contacts-reducer/contacts.actions';
import PageLoading from '../../components/molecules/page-loading';
import { getNotifications } from '../../reducers/notifications-reducer/notifications.actions';
import ComplianceDetailsSidebar from '../../components/organisms/assets-list/compliance-details-sidebar';

function AssetDocumentsPage() {
    const dispatch = useDispatch();
    const { selectedComplianceItem, selectedComplianceItemDocuments } =
        useContext(SelectedComplianceItem);

    const { currentAsset, currentBuilding, currentTenant } =
        useSelector(assetSelector);

    const asset = useMemo(
        () => currentTenant || currentBuilding || currentAsset,
        [currentTenant, currentBuilding, currentAsset]
    );

    const { assetId, buildingId, tenantId } = useParams();

    const [loading, setLoading] = useState(false);

    const getData = useCallback(async () => {
        try {
            setLoading(true);
            if (tenantId && !currentTenant) {
                await dispatch(getTenant(tenantId));
            }
            if (buildingId && !currentBuilding) {
                await dispatch(getBuilding(buildingId));
            }
            if (assetId && !currentAsset) {
                await dispatch(getAsset(assetId, false, true));
            }
            await Promise.all([
                await dispatch(getAllContactsForBusiness()),
                await dispatch(getNotifications()),
            ]);
        } finally {
            setLoading(false);
        }
    }, [
        tenantId,
        currentTenant,
        buildingId,
        currentBuilding,
        assetId,
        currentAsset,
        dispatch,
    ]);

    const showDocumentSidebar = useMemo(
        () =>
            !!selectedComplianceItem &&
            !!asset &&
            (!!selectedComplianceItem?.uploadRequest ||
                selectedComplianceItemDocuments?.length > 0),
        [asset, selectedComplianceItem, selectedComplianceItemDocuments]
    );

    useEffect(() => {
        if (!loading) {
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return loading ? (
        <PageLoading />
    ) : (
        <Pane display="flex" width="100%" margin={20} marginTop={0}>
            <Pane
                display="flex"
                flexDirection="column"
                height="100%"
                width="100%"
            >
                <Pane
                    display="flex"
                    backgroundColor="#f2f4fb"
                    justifyContent="space-between"
                    width="100%"
                    position="sticky"
                    zIndex={1}
                >
                    <Pane
                        display="flex"
                        flexDirection="column"
                        gap={24}
                        paddingY={24}
                        width="100%"
                    >
                        <Breadcrumbs />
                        <Pane
                            display="flex"
                            alignItems="center"
                            width="100%"
                            justifyContent="space-between"
                        >
                            <LinkHeading
                                size={900}
                                fontSize={28}
                                display="flex"
                            >
                                {asset?.assetName}
                            </LinkHeading>
                        </Pane>
                    </Pane>
                </Pane>
                <Pane
                    display="flex"
                    flex={1}
                    width="100%"
                    justifyContent="space-between"
                    position="relative"
                    height="calc(100% - 280px)"
                >
                    <ComplianceSidebar
                        key={asset?.id}
                        complianceGroups={asset?.complianceGroups}
                    />
                    <Pane
                        display="flex"
                        width="100%"
                        paddingLeft={340}
                        paddingRight={showDocumentSidebar ? 340 : 0}
                    >
                        <DocumentsList />
                    </Pane>
                    {showDocumentSidebar && <ComplianceDetailsSidebar />}
                </Pane>
            </Pane>
        </Pane>
    );
}

const LinkHeading = styled(Heading)`
    display: flex;
    &:hover {
        color: #5ec090;
    }
`;

export default (props) => (
    <SelectedComplianceItemProvider>
        <AssetDocumentsPage {...props} />
    </SelectedComplianceItemProvider>
);
