import networkService from '../network-service/network.service';
import assetUrls from './asset-service-urls';
import { assetApiModel } from '../../../models';
import documentService from '../document-service/document.service';

async function getAssetById(id) {
    const url = assetUrls.getAssetByIdUrl();
    const config = { params: { asset_id: id } };
    const response = await networkService.makeGet(url, config);

    const featuredImage = response.images.find((image) => image.is_featured);
    if (featuredImage) {
        const fileUrl = Object.values(
            await documentService.getFilesDownloadLink([
                {
                    assetId: response.bucket_id,
                    fileId: featuredImage.file_id,
                    fileKey: `${response.bucket_id}/${featuredImage.file_id}`,
                },
            ])
        )[0];

        featuredImage.bucket_id = response.bucket_id;
        featuredImage.url = fileUrl;

        response.images = [
            featuredImage,
            ...response.images.filter((image) => !image.is_featured),
        ];
    }

    return response;
}

function createAsset(assetObject) {
    const url = assetUrls.createAssetUrl();

    const linkedToPark =
        assetObject.propertyType === 'building' && !!assetObject.parentId;

    const data = assetApiModel({
        ...assetObject,
        linkedToPark,
    });
    return networkService.makePost(url, data);
}

function updateAsset(assetObject) {
    const url = assetUrls.updateAssetUrl();
    const data = assetApiModel(assetObject);
    return networkService.makePut(url, data);
}

function getAssets(search) {
    const url = assetUrls.getAssetsUrl();
    const config = { params: { search } };
    return networkService.makeGet(url, config);
}

export default {
    getAssetById,
    createAsset,
    updateAsset,
    getAssets,
};
